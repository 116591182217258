.contact_wrapper {
  background-color: #171717;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  color: white;
  padding-left: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  .left {
    width: 50%;
    h1 {
      font-size: 48px;
    }
    p {
      font-size: 16px;
    }
    .card {
      box-shadow: 6px 6px 10px #000, 1px 1px 10px hsla(0, 0%, 100%, 0.6);
      padding: 10px;
      border-radius: 12px;
      margin-top: 20px;
      width: 350px;
      position: relative;
      .mail {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
      }
      span {
        color: #0563bb;
      }
    }
  }
  .right {
    width: 50%;
    // text-align: right;
    .form_wrapper {
      width: 70%;
      box-shadow: 6px 6px 10px #000, 1px 1px 10px hsla(0, 0%, 100%, 0.6);
      border: unset;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      &.flipped {
        transform: rotateY(180deg);
        .back {
          display: none;
        }
      }

      .front,
      .back {
        backface-visibility: hidden;
      }

      .front {
        transform: rotateY(180deg);
      }
      .back {
        .content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .click_me_text {
            font-size: 32px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.form_wrapper {
  padding: 40px;
  border: 1px solid white;
  border-radius: 12px;
  .heading {
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  input {
    background-color: transparent;
    margin: 15px 0;
    // border: 1px solid white;
    // border-radius: 12px;
    padding: 5px 15px;
    width: 100%;
    border-bottom: 1px solid #7a7a7a;
    &:focus {
      outline: none;
    }
    &:-webkit-autofill {
      // background-color: #000 !important;
      -webkit-box-shadow: 0 0 0 30px #171717 inset;
      -webkit-text-fill-color: white;
    }
  }
  textarea {
    background-color: transparent;
    margin-bottom: 10px;
    // border: 1px solid white;
    outline: none;
    border-bottom: 1px solid #7a7a7a;
    padding: 5px 15px;
    width: 100%;
    max-height: 200px;
    min-height: 50px;
    &:-webkit-autofill {
      // background-color: #000 !important;
      -webkit-box-shadow: 0 0 0 30px #171717 inset;
      -webkit-text-fill-color: white;
    }
  }
}

@media (max-width: 700px) {
  .contact_wrapper {
    padding: 50px;
    flex-direction: column;
    gap: 0;
    height: 100%;
    padding-bottom: 100px;
    .left {
      width: 100%;
      text-align: center;
      h1 {
        font-size: 32px;
      }
      p {
        font-size: 12px;
      }
      .card {
        text-align: left;
        width: 100%;
        margin: 50px auto;
      }
    }
    .right {
      width: 100%;
      .form_wrapper {
        width: 100%;

        .back {
          .content {
            .click_me_text {
              font-size: 22px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
