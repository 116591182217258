#cursor {
    position: fixed;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    pointer-events: none;
    background-color: #303336ff;
    opacity: 0.7;
    border: 1px solid #fff;
    top: 0;
    left: 0;
    transition: transform ease-out 0ms;
    z-index: 1000;
  }
  @media (max-width: 1024px) {
    #cursor {
      display: none;
    }
  }