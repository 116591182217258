.work-wrapper {
  overflow: hidden;
  background-color: #171717;
  width: 100%;
  color: #e8e8e8;
  padding-left: 18%;
  padding-top: 50px;
  padding-bottom: 50px;
  .main_heading {
    margin: 40px 40px 80px 0;
    padding: 10px;
    box-shadow: 6px 6px 10px #000, 1px 1px 10px hsla(0, 0%, 100%, 0.6);
    border-radius: 10px;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: all 250ms;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: #e8e8e8;
      border-radius: 10px;
      z-index: -1;
      transition: all 250ms;
    }
    &:hover {
      color: #2a2929;
    }
    &:hover::before {
      width: 100%;
    }
  }
  .works {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 100px;
    .company_wrapper {
      position: relative;
      &::before {
        border-right: 1px solid white;
        content: "";
        right: -50px;
        position: absolute;
        height: 100%;
        border-radius: 12px;
      }
    }
    .project_wrapper {
      align-items: baseline;
      .card {
        padding: 20px;
      }
    }
    .card {
      box-shadow: 6px 6px 10px #000, 1px 1px 10px hsla(0, 0%, 100%, 0.6);
      border-radius: 12px;
      padding: 20px 0;
      scale: 1;
      transition: all 500ms;
      gap: 40px;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
      &.selected {
        // background-color: #e8e8e8;
        background-color: #0563bb;
        scale: 1.1;
        // color: #2a2929;
      }
      .company_name {
        font-size: 24px;
        white-space: nowrap;
      }
      .duration {
        font-size: 10px;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 700px) {
  .work-wrapper {
    padding: 50px;
    .main_heading {
      margin: 40px 40px 80px 40px;
    }
    .works {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 100px;
      flex-direction: column;
      .company_wrapper {
        position: relative;
        width: 100%;
        &::before {
          border-bottom: 1px solid white;
          border-right: unset;
          bottom: -50px;
          right: 0;
          border-radius: 0;
          width: 100%;
        }
      }
      // .project_wrapper {
      //   width: 100%;
      //   gap: 0;
      //   .card {
      //     padding: 50px;
      //   }
      // }

      .project_wrapper {
        width: 100%;
        flex-wrap: nowrap;
        padding: 20px;
        overflow: auto;
        .card {
          padding: 50px;
          width: unset;
        }
      }
      .card {
        gap: 20px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 30px;
        }
        &.selected {
          background-color: #0563bb;
          scale: 1;
        }
        .company_name {
          font-size: 18px;
          white-space: nowrap;
        }
        .duration {
          font-size: 10px;
          white-space: nowrap;
        }
      }
    }
  }
}
