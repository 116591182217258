.skills-wrapper {
  // height: 100vh;
  width: 100vw;
  background-color: #171717;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  .heading {
    animation: bounce 6s ease-in-out infinite;
    height: 95vh;
    h1 {
      width: 500px;
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .bg-img-wrapper {
    top: 77%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .text-left-wrapper {
    animation: float ease-in-out infinite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.bubble {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);

  position: absolute;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes bounce {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(15px);
  }
  100% {
    transform: translatey(0px);
  }
}
  
